import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IInvitation } from '@shared/types/invitations';

@Injectable({
    providedIn: 'root'
})
export class InvitationService {
    private http = inject(HttpClient);
    private baseUrl: string = 'api/invitations';

    public inviteUser(data: IInvitation): Observable<any> {
        return this.http.post<any>(this.baseUrl, data);
    }
}

export function getInvitationErrorMessage(translations: Record<string, string>, error: any): string {
    if (error.status === 400) {
        return translations.couldNotInviteBadRequest;
    }
    
    if (error.status === 424 && error.error.includes('BadRequest')) {
        return translations.dependencyFailureBadRequest;
    }
    
    if (error.status === 424 && error.error.includes('NotFound')) {
        return translations.dependencyFailureNotFound;
    }
    
    if (error.status === 424 && error.error.includes('InternalServerError')) {
        return translations.dependencyFailureInternalServerError;
    }
    
    if (error.status === 424) {
        return translations.dependencyFailureUnexpectedError;
    }

    return translations.failedRequest;
}